import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import PrimeVue from 'primevue/config'
import Nora from '@primevue/themes/nora';
import VueCryptojs from 'vue-cryptojs'




createApp(App).use(store).use(router).use(PrimeVue, {
    theme: {
        preset: Nora,       
    }
 }).use(VueCryptojs).mount('#app')