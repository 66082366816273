<template>
    <div class="modal fade" id="myModal" tabindex="-1" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog" :class="{'modal-lg': size === 'large', 'modal-sm': size === 'small'}">
        <div class="modal-content">
          <div class="modal-header">
            <div class="row">
              <img src="/img/Logo-gototix-page.png" style="height: 22px;margin-top: 4px;margin-left: 20px;"> &nbsp;  &nbsp; <h5 class="modal-title" id="myModalLabel">{{ title }}</h5>            
            </div>            
            
        <button type="button" class="close btn-close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
          </div>
          <div class="modal-body">
            <template v-if="typeModal === 'form'">
              <form @submit.prevent="handleSubmit">
                <div v-for="(field, index) in formFields" :key="index" class="mb-3">
                  <label :for="field.name" class="form-label">{{ field.label }}</label>                  
                  <input v-if="field.type === 'text'" :type="field.type" :id="field.name" v-model="formData[field.name]" class="form-control" />
                  <input v-if="field.type === 'password'" :type="field.type" :id="field.name" v-model="formData[field.name]" class="form-control" />                  
                </div>

                <div v-if="false"> 
                  <strong>Continuar con:</strong> <br>
                  <button class="btn btn-secondary"> <img width="25" src="https://dataclubes.com/webApp/img/Logos/google-logo.5867462c.svg" alt=""> GOOGLE </button>                  
                </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" id="modalClose">Cerrar</button>
        <button class="btn btn-primary" >{{ submitText }}</button> 
      </div>
              
              </form>
            </template>
            <template v-else-if="typeModal === 'info'">
              <p>{{ infoText }}</p>
            </template>
          </div>
          <div class="modal-footer" v-if="typeModal === 'info'">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, PropType } from 'vue';
  
  
  export default defineComponent({
    name: 'BaseModal',
    props: {
      typeModal: {
        type: String as PropType<'form' | 'info'>,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
      formFields: {
        type: Array as PropType<Array<{ name: string, label: string, type: string }>>,
        default: () => [],
      },
      submitText: {
        type: String,
        default: 'Submit',
      },
      infoText: {
        type: String,
        default: '',
      },
      size: {
        type: String as PropType<'small' | 'large'>,
        default: '',
      },
    },
    data() {
      return {
        formData: {} as { [key: string]: any },
      };
    },
    methods: {
      handleSubmit() {
        // Cerrar el boton..        
        // Validar los datos antes de enviarelo.
        if ( this.formData.username == '' || this.formData.password == '' ){
          return 0; 
        }

       const boton = document.getElementById('modalClose');
       boton?.click(); 
      // this.$emit('submit', this.formData);
         this.$emit('sendPost', this.formData);

      },
    },
  });
  </script>
  
  <style scoped>
  /* Estilos específicos para el modal */
  </style>