<template>

   <!-- Topbar Start -->
   <div class="container-fluid">       
        <div class="row align-items-center bg-light py-3 px-xl-5 d-none d-lg-flex">
            <div class="col-lg-4">
              
                <a href="" class="text-decoration-none">
                  <img src="/img/Logo-gototix-page.png" style="height: 45px;">                    
                </a>
            </div>
            <div class="col-lg-4 col-6 text-left">
                <form action="">
                    <div class="input-group">
                        <input type="text" class="form-control" placeholder="Buscar evento" v-model="modelCurrentList">
                        <div class="input-group-append">
                            <span class="input-group-text bg-transparent text-primary">
                                <i class="fa fa-search"></i>
                            </span>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-lg-4 col-6 text-right"> 
              
                <div class="d-inline-flex align-items-center">
                    <div class="btn-group">
                        <button type="button" class="btn btn-sm btn-light dropdown-toggle" data-toggle="dropdown"> {{ menuConfig.LabelAccount }} </button>
                        <div class="dropdown-menu dropdown-menu-right" >                            
                            <button v-if="!menuConfig.IsLogin" class="dropdown-item" type="button" data-toggle="modal" data-target="#myModal" data-bs-toggle="modal" data-bs-target="#myModal" @click="openFormModal"> Iniciar Sesión</button>
                            <button v-if="false" class="dropdown-item" type="button" data-toggle="modal" data-target="#myModal" data-bs-toggle="modal" data-bs-target="#myModal" @click="openRegisterModal"> Registrate </button>
                            <button v-if="menuConfig.IsLogin"  class="dropdown-item" type="button"  @click="logoutUser">
                                <router-link to="/"> Cerrar Sesión </router-link> </button>
                        </div>
                    </div>
                    <div class="btn-group mx-2">
                        <button type="button" class="btn btn-sm btn-light dropdown-toggle" data-toggle="dropdown">DOP</button>
                        <div class="dropdown-menu dropdown-menu-right">
                            <button class="dropdown-item" type="button">DOP</button>                            
                        </div>
                    </div>
                    <div class="btn-group">
                        <button type="button" class="btn btn-sm btn-light dropdown-toggle" data-toggle="dropdown">ES</button>
                        <div class="dropdown-menu dropdown-menu-right">
                            <button class="dropdown-item" type="button">ES</button>                            
                        </div>
                    </div>
                </div>
                <div class="d-inline-flex align-items-center d-block d-lg-none">
                    <a href="" class="btn px-0 ml-2">
                        <i class="fas fa-heart text-dark"></i>
                        <span class="badge text-dark border border-dark rounded-circle" style="padding-bottom: 2px;">0</span>
                    </a>
                    <a href="" class="btn px-0 ml-2">
                        <i class="fas fa-shopping-cart text-dark"></i>
                        <span class="badge text-dark border border-dark rounded-circle" style="padding-bottom: 2px;">0</span>
                    </a>
                </div>
            
                             
            </div>
        </div>
    </div>
    <!-- Topbar End -->


    <!-- Navbar Start -->
    <div class="container-fluid bg-dark mb-30">
        <div class="row px-xl-5">
            <div class="col-lg-3 d-none d-lg-block"  v-if="menuConfig.IsLogin">
                <a class="btn d-flex align-items-center justify-content-between bg-primary w-100" data-toggle="collapse" href="#navbar-vertical" style="height: 65px; padding: 0 30px;">
                    <h6 class="text-dark m-0"><i class="fa fa-bars mr-2"></i>Menu Opciones</h6>
                    <i class="fa fa-angle-down text-dark"></i>
                </a>
                <nav class="collapse position-absolute navbar navbar-vertical navbar-light align-items-start p-0 bg-light" id="navbar-vertical" style="width: calc(100% - 30px); z-index: 999;">
                    <div class="navbar-nav w-100">
                       <div v-for="item in menuConfig.itemAdmin" :key="item.name">
                        <div class="nav-item dropdown dropright" v-if="item.items.length > 0">
                            <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown"> {{ item.name }} <i class="fa fa-angle-right float-right mt-1"></i></a>
                            <div class="dropdown-menu position-absolute rounded-0 border-0 m-0">
                                <div v-for="subItem in item.items" :key="subItem.name" >
                                    <router-link  :to="subItem.to" class="dropdown-item"  v-if="subItem.visible">
                                     {{ subItem.name }} 
                                    </router-link>
                                </div>
                                                                                      
                            </div>
                        </div>
                        <router-link v-if="item.items.length == 0 &&  item.visible" :to="item.to" class="nav-item nav-link">{{ item.name }}</router-link>
                        

                        </div>
                        
                                                                                          
                    </div>
                </nav>
            </div>
            <div class="col-lg-9">
                <nav class="navbar navbar-expand-lg bg-dark navbar-dark py-3 py-lg-0 px-0">
                    <a href="" class="text-decoration-none d-block d-lg-none">
                        <span class="h1 text-uppercase text-white  px-2"><img src="/img/Cellphonelogo.png"> </span>
                    </a>
                    <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse justify-content-between" id="navbarCollapse">
                        <div class="navbar-nav mr-auto py-0">
                            <div  v-for="item in menuConfig.items" :key="item.name">
                                <router-link :to="item.to" v-if="item.visible" class="nav-item nav-link">{{item.name}}</router-link> 
                            </div>                          

                            <div class="nav-item dropdown d-block d-lg-none">
                                <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown">Gestionar <i class="fa fa-angle-down mt-1"></i></a>
                                <div class="dropdown-menu bg-primary rounded-0 border-0 m-0">
                                    <a href="cart.html" class="dropdown-item">Mis eventos</a>
                                    <a href="checkout.html" class="dropdown-item">Reportes </a>
                                </div>
                            </div>

                        </div>
                        <div class="navbar-nav ml-auto py-0 d-none d-lg-block">
                            <a href="" class="btn px-0">
                                <i class="fas fa-heart text-primary"></i>
                                <span class="badge text-secondary border border-secondary rounded-circle" style="padding-bottom: 2px;">0</span>
                            </a>                           
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
    <!-- Navbar End -->

    <BaseModal  
      v-if="showModal"     
      :typeModal="modalType"
      :title="modalTitle"
      :formFields="formFields"
      :submitText="submitText"
      :infoText="infoText"      
      @sendPost = "handleFormSubmit"
    />    
  <router-view  />
  <!-- Button trigger modal.. @submit="handleFormSubmit" -->
<!-- Modal -->
</template>

<script lang="ts">
import { defineComponent, onMounted, computed   } from 'vue';
import { mapState, mapGetters, useStore } from 'vuex';
import { useRoute } from 'vue-router';
import BaseModal from './components/BaseModal.vue';
import { startSesion } from '@/firebaseService';


export default defineComponent({
  name: 'App',
  components: {
    BaseModal,
  },
  
  data() {
    return {
      showModal: false,
      modalType: 'form',
      modalTitle: '',
      formFields: [
        { name: 'username', label: 'Usuario o Correo Electronico', type: 'text' },        
        { name: 'password', label: 'Contraseña', type: 'password' },
      ],
      submitText: 'Iniciar',
      infoText: '',
      modelCurrentList:[]
    };
  },

  methods: {
    openFormModal() {
      this.modalType = 'form';
      this.modalTitle = 'Iniciar Sesión en GOTOTIX';
      this.showModal = true;
    },    

    hijo(){
      alert("Llmado desde algun hijo renderizado"); 
    }, 
    

    logoutUser(){  
        const updatedItem = { LabelAccount: 'Mi cuenta', IsLogin: false, Usuario: {}, Fecha: Date.now()};        
        this.editMenuItem(updatedItem); 
        localStorage.removeItem("menuConfig");
    },

    openRegisterModal() {
        this.formFields = [
        { name: 'Nombre', label: 'Nombre', type: 'text' },                    
        { name: 'Correo', label: 'Correo Electronico', type: 'text' },        
        { name: 'password', label: 'Contraseña', type: 'password' },
      ]; 
      this.modalType = 'form';
      this.modalTitle = 'Registrate & Crear una cuenta.';
      this.showModal = true;
    },
    openInfoModal() {
      this.modalType = 'info';
      this.modalTitle = 'Information';
      this.infoText = 'This is some important information.';
      this.showModal = true;
    },
    
    async handleFormSubmit (formData: any) {      
      // const updatedItem = { LabelAccount: 'Hola, Grequis', IsLogin: true};        
      // this.editMenuItem(updatedItem);           
      const user:any = await startSesion( formData );
      if(!user.IsLogin){
        alert("Usuario incorrecto"); 
        return false;
      }  

      if(user.IsLogin){        
        const updatedItem = { LabelAccount: 'Hola, ' + user.user.Nombres, IsLogin: true, Usuario: user, Fecha: Date.now()};        
        this.editMenuItem(updatedItem);

      } else {
        alert("Usuarios incorrecto."); 
        
      }
      return true; 
      
    },
  },
  
  computed: {
    ...mapState({
      menuConfig: (state: any) => state.menuConfig,
    }),
    ...mapGetters(['getMenuConfig']),
  },
  setup() {

    const store = useStore();
    const route = useRoute();

    const verificarMenuConfig = () => {

      const menuConfig = computed(() => store.state.menuConfig);
      if (menuConfig.value) {
        if(menuConfig.value.IsLogin){                    
          return true; 
        }
      } 
        return false;       
    };

    onMounted(() => {        
      if(!verificarMenuConfig()){
        store.dispatch('fetchMenuConfig');
      }
            
    });

    const editMenuItem = (element: any) => {
      store.dispatch('editConfigItem', element);   
    };

    const isHomePage = computed(() => route.path === '/');
    return {editMenuItem, isHomePage}

  },
});
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;  
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
