<template>
  <!-- Carousel Start -->
  <div class="container-fluid mb-3">
        <div class="row px-xl-5">
            <div class="col-lg-8">
                <div id="header-carousel" class="carousel slide carousel-fade mb-30 mb-lg-0" data-ride="carousel">
                    <ol class="carousel-indicators">
                        <li  data-target="#header-carousel" :data-slide-to="index" :class="getActiver(index)" v-for="(portada, index) in obtenerEventoPortada()" :key="index"></li>                        
                                              
                    </ol>
                    <div class="carousel-inner">
                        <div :class="'carousel-item position-relative ' + getActiver(index)" style="height: 430px;" v-for="(portada, index) in obtenerEventoPortada()" :key="index">
                            <img  class="position-absolute w-100 h-100 " :src="portada.ImagenPortada" style="object-fit: cover;">
                            <div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
                                <div class="p-3" style="max-width: 700px;">
                                    <h1 class="display-4 text-white mb-3 animate__animated animate__fadeInDown">{{ portada.NombreCorto}}</h1>
                                    <p class="mx-md-5 px-5 animate__animated animate__bounceIn"> {{ portada.Nombre}} </p>
                                    <router-link :to="{name: 'DetalleEvents', params: {id: portada.Id }}" class="btn btn-outline-light py-2 px-4 mt-3 animate__animated animate__fadeInUp"><strong> Registrate  </strong> </router-link>                                     
                                </div>
                            </div>
                        </div>
                       
                        
                    </div>
                </div>
            </div>
            <div class="col-lg-4" >
                <div class="product-offer mb-30" style="height: 200px;" v-for="(portada, ind) in getSecundarySlideShow()" :key="ind" >
                    <img class="img-fluid" :src="portada.ImagenPortada" alt="">
                    <div class="offer-text">
                        <h6 class="text-white text-uppercase">No te lo pierdas</h6>
                        <h3 class="text-white mb-3">{{portada.NombreCorto}}</h3>
                        <router-link :to="{name: 'DetalleEvents', params: {id: portada.Id }}" class="btn btn-primary" > Registrate ahora! </router-link>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Carousel End -->    
    
  <div class="home">    
    <HelloWorld msg="Carusel" :events="events"/>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src
import { createEvent, getEvents, updateEvent, deleteEvent } from '@/firebaseService';
import { paginate } from '@/utils/pagination';

 @Options({
  components: {
    HelloWorld,
  },
})



export default class HomeView extends Vue {

    events: any[] = []; 
    portada = [];
    keySlideActive = null; 

     async fetchEvents() {
        console.log("fetch eventos.. "); 
        this.events = Object.values(await getEvents());        
    }

    getSecundarySlideShow(){

        return paginate(this.events, 1, 2); 

    }

    getActiver(index: any){

        if(this.keySlideActive == null ){
            this.keySlideActive = index; 
            return "active"; 
        }
        if(this.keySlideActive == index){
            return "active"; 
        }
        return ""; 

    }

    obtenerEventoPortada(){

        if(this.portada.length == 0){

        return this.events.filter((ev) => {            
            return ev.Slideshow;    
  })
        }        
        return this.portada; 
    } 

    mounted() {        
         this.fetchEvents(); 
    }
    
}
</script>
