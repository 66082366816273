// src/utils/pagination.ts
import { format, parse } from 'date-fns';
import { es } from 'date-fns/locale';
import CryptoJS from 'crypto-js';
import { getFunctions, httpsCallable } from 'firebase/functions';


export const paginate = (array: any[], pageNumber: number, pageSize: number) => {
    const startIndex = (pageNumber - 1) * pageSize;
    return array.slice(startIndex, startIndex + pageSize);
  };

  export  const formatCurrency = (amount: number, currency: string) =>{
    if(amount == null || amount == undefined ){
      return 0;
    }
    return amount.toLocaleString('es-US', { style: 'currency', currency: currency });
  };

  export  const getMD5 = (text: string) =>{
    return CryptoJS.MD5(text).toString();     
  };

  export const generateUUID = () => {
    // Replace x and y in the template with random hex digits
    const value = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
    return value; 
  };


export const formatDateString = (dateString: string) => {
    const parsedDate = parse(dateString, 'yyyy-MM-dd HH:mm:ss', new Date());
  
    return {
      fullDate: format(parsedDate, 'EEEE dd MMMM, yyyy hh:mm a', { locale: es }),  // Sábado 27 agosto, 2024 8:05 PM
      shortDate: format(parsedDate, 'dd/MM/yyyy', { locale: es }),  // 27/08/2024
      serverFormat: format(parsedDate, 'yyyy-MM-dd HH:mm:ss', { locale: es }),  // 27/08/2024
      serverShortFormat: format(parsedDate, 'yyyy-MM-dd', { locale: es }),  // 27/08/2024
      time: format(parsedDate, 'hh:mm a', { locale: es }),  // 08:05 PM
      day: format(parsedDate, 'EEEE', { locale: es }),  // Sábado
    };
  };

    export const  enviarCorreoTicket = (elemt: any) =>  {
      const functions = getFunctions();
      const sendEmail = httpsCallable(functions, 'sendEmailAttach');

      let totalTex = ""; 
      for(const i in elemt.formularios){
        for(const cc in elemt.formularios[i]){
          if(cc != "QR" && cc != "Id" ){
            totalTex += '<div><strong> ' + cc +': </strong> ' + elemt.formularios[i][cc] + '</div>';
          }           
        }
        totalTex +=  '<img src="cid:unique-image-id'+ elemt.formularios[i].Id + '" alt="Imagen" style="width:300px;height:auto;"/> <hr>';
      }
      const htmlContent = `
      <h1> Evento: '${elemt.Nombre}'</h1>
      <p>Muchas gracias <strong> ${elemt.Contacto} </strong> por confiar y participar con nosotros en este evento <strong>en Gototix</strong></p>
      <p>A continuación los detalles de sus ticket con el QR para la entrada: </p>            
      ${totalTex}
      <p> Recuerda hacer preparativos para asistir al evento.</p> 
      GOTOTIX     
    `;
      const attachments = elemt.attachments; 
      /* [
        {
          filename: 'TuCodigoQREvento.jpg',
          content: '', // Puedes pasar contenido en base64 o una URL en "path"
          path: 'https://dataclubes.com/ReporteCarnet/get_QR/7?qQr=OrdenCompra/1ec02727-1e43-4b50-ae45-896ec2db4abd/Ticket/459555a1-50c8-47a5-b8c6-a0c05ab40b5e',
          cid: 'unique-image-id', // Este ID se usa en el contenido HTML
        }
          */
        /*,
        {
          filename: 'archivo.pdf',
          content: '', // Base64 o path si es un archivo
          path: '/path/to/archivo.pdf',
        },
        */
    //  ];

      // Llamar a la función en Firebase
      
      try {
        const response: any = sendEmail({
          destinatario: elemt.Destino,
          asunto: elemt.Asunto,
          htmlContent,
          attachments,
        });

        if (response.data.success) {
          console.log('Correo enviado:', response.data.message);
        } else {
          console.error('Error al enviar el correo:', response.data.message);
        }
      } catch (error) {
        console.error('Error en la función sendEmail:', error);
      }
    }

    export const formatearLista =  (lista: { Pregunta: string; Value: any, FechaRespuesta: string }[], resumen: any) => {
      let begining = null; 
      const regreso = []; 
      let obj: Record<string, any> = {}; 
      let listTicket: Record<string, any> = [];
      if(resumen.Ticket !== undefined || resumen.Ticket != null){
          listTicket = Object.values(resumen.Ticket); 
      }
       // Object.values(resumen.Ticket? {}: resumen.Ticket ); 
      for(const i in  lista){
          const key: any = i.toString().split("_");             
          if(begining == null){ begining = key[0]; }            
          if(begining != key[0]){
              begining = key[0]; 
              regreso.push(obj); 
              obj = {}; 
          }            
          obj[lista[i].Pregunta] = (typeof(lista[i].Value) == "object")? lista[i].Value.Id : lista[i].Value;           
          obj["Monto"] = (parseFloat(resumen.Total)/parseFloat(resumen.Cantidad) ); 
          obj["QR"] = "";
          // El Ticket con su Codigo QR lo tiene el resumen.
          if(listTicket[key[0]]){
              obj["QR"] = listTicket[key[0]].QR;
              obj["Id"] = listTicket[key[0]].Id;                                 
          }
      }
      regreso.push(obj); 
      return regreso; 
    }; 
  