import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },    
  {
    path: '/e',
    name: 'DetalleEvento',    
    component: () => import('../views/eventosView.vue')
  },
  {
    path: '/e/:id',
    name: 'DetalleEvents',    
    component: () => import('../views/eventosView.vue')
  },
  {
    path: '/myEvents',
    name: 'EventoAdmin',    
    component: () => import('../views/EventoAdmView.vue')
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: () => import('../views/CheckoutView.vue')
  }, 
  {
    path: '/Reportes',
    name: 'reporte',
    component: () => import('../views/ReporteView.vue')
  },
  {
    path: '/Reportes/Pagos/Portal/Cardnet/001',
    name: 'CardNetView ',
    component: () => import('../views/reportes/CardnetView.vue')
  },
  {
    path: '/Eventos/Asistencia/LectorQR',
    name: 'LectorQR ',
    component: () => import('../views/LectorEventoView.vue')
  },
  
  {
    path: '/Reportes/Detalle/Evento',
    name: 'reporteDetalle',
    component: () => import('../views/ReporteDetalleView.vue')
  }, 
  {
    path: '/usuarios',
    name: 'usuarios',
    component: () => import('../views/UsuariosView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
